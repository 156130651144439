<template>
    <vue-plotly style="width:100%;" :data="plot_data" :layout="layout" :options="options"/>
</template>

<script>

import VuePlotly from '@statnett/vue-plotly'

export default {
  name: 'Barchart',
  components: { VuePlotly },
  props: {
    ytitle: {
      type: String,
      default: ''
    },
    xtitle: {
      type: String,
      default: ''
    },
    hovertext: {
      type: Array,
      default: () => []
    },
    names: {
      type: Array,
      default: () => []
    },
    x: {
      type: Array,
      default: () => []
    },
    y: {
      type: Array,
      default: () => []
    },
    error_y: {
      type: Array,
      default: () => []
    },
    showlegend: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: 420
    },
    title: {
      type: String,
      default: ''
    },
    marginBottom: {
      type: Number,
      default: 140
    },
    tickangle: {
      type: Number,
      default: 45
    },
    niveau: {
      type: String,
      default: ''
    },
    tickformat:{
      type: String,
      default: ''
    },
    hoverinfo:{
      type: Array,
      default: () => []
    },
    tickvals:{
      type: Array,
      default: () => []
    },
    ticktext:{
      type: Array,
      default: () => []
    },
    tickmode: {
      type: String,
      default: ''
    },
  },
  data () {
    return {
      text: [],
      colorbars: ['rgba(20,66, 115,1)'],
      layout: {
        hovermode: 'closest',
        paper_bgcolor: 'rgba(0,0,0,0)',
        plot_bgcolor: 'rgba(0,0,0,0)',
        margin: {
          l: 50,
          r: 100,
          b: this.marginBottom,
          t: 25,
          pad: 0},
        showlegend: this.showlegend,
        height: this.height,
        xaxis: {
          tickangle: this.tickangle,
          title: {
            text: this.xtitle,
            font: {
              family: 'Gotham',
              size: 14
            },
          },
          tickmode: this.tickmode,
          tickvals: this.tickvals,
          dtick: this.dtick
        },
        title: {
          text: this.title,
          font: {
            family: 'Gotham',
            size: 14
          },
          xref: 'paper',
          x: 0.05,
          y: 0.99
        },
        yaxis: {
          range: [0, 5],
          title: {
            text: this.ytitle,
            font: {
              family: 'Gotham',
              size: 14
            },
          },
          tickformat: this.tickformat
        }
      },
      options: {
        displayModeBar: false,
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  computed: {
    plot_data () {
      var plot_data = []
      for (var i = 0; i < this.x.length; i++) {
        plot_data.push(
          {
            type: 'bar',
            name: this.names[i],
            x: this.x[i],
            y:this.y[i],
            text: this.hovertext[i],
            error_y:{
              type: 'data',
              array: this.error_y[i],
              visible: true  
            },
            marker: {
              color: this.colorbars[i]
            }
          })
      }
      return plot_data
    }
  },
}

</script>
