<template>
  <div id="dementie-information">
  <p v-if="errorMessage" v-bind:class="{'error': errorMessage}" class="error-message">{{ errorMessage }}</p>
    <!-- Only display the data if the errorMessage property is not set -->
  <div v-if="!errorMessage">
    <div class="about p-5 overflow-hidden container-fluid">
    <h1 style="margin-bottom: 50px">Netwerkanalyse resultaten per vraag</h1>

    <div class="d-flex flex-row justify-content-between align-items-start">
      <div class="p-2 section-buttons">
        <p class="result-information">Selecteer hieronder voor welke categorie u de Netwerkanalyse resultaten wilt zien</p>
        <div class="my-button-group">
          <b-button 
          tabindex="-1" 
          size="sm" 
          class="justify-content-center select-toggle-button" 
          style="background-color: white; border: white">
            <b-button tabindex="0" 
            autofocus 
            role="button" 
            :aria-pressed="false"
            v-for="(questiongroup, index) in questionGroupIds" 
            :key="index" 
            variant="outline-primary"
            :class="{active: selectedQuestionGroupIds.includes(questiongroup), 'button-space': true, 'icon-space': true}"
            @click="togglePhase(questiongroup)"
            class="flex-fill select-toggle-button"
            style="margin-right: 60px;">
            <span>
              <font-awesome-icon v-if="selectedQuestionGroupIds.includes(questiongroup)" icon="check" size="1x" class="custom-icon"/><br>
            </span>
            {{ questionGroups[index] }}
        </b-button>
      </b-button>
    </div>
      </div>

      <div class="p-2 section-information">
        <section class="resultaten-information">
          <div class="d-flex flex-row justify-content-center align-items-center">
            <div class="w-75 navbar-information">

              <div class="row justify-content-center">
                <div class="col-10 align-self-center">
                  <img :src="nrinformation" class="nrinformation-img" alt="nrinformation icon">
                </div>
              </div>

              <div class="row justify-content-center">
                <div class="col-12 align-items-center">
                  <div class="row justify-content-center resultaten-info-section">

                    <div class="col-4 align-self-center  explanation-navbar">
                        <img :src="one" class="numbers-img" alt="two icon">
                        <p class="explanation-navbar-text">De <span class="bold-text">balk</span> is een weergave van het gemiddelde resultaat. </p>
                    </div>

                    <div class="col-4 align-self-center explanation-navbar">
                      <img :src="two" class="numbers-img" alt="one icon">
                      <p class="explanation-navbar-text">Foutbalken (<span class="bold-text">whiskers</span>) zijn weergaven van de variatie die bij elk gegevenspunt hoort. </p>
                    </div>

                    <div class="col-4 align-self-center  explanation-navbar">
                      <img :src="three" class="numbers-img" alt="three icon">
                      <p class="explanation-navbar-text">Het getal tussen haakjes staat voor het aantal respondenten die de vraag beantwoordt hebben per sector.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="p-2 explanation-navbar-text" style="margin: 1rem; height: 12em;">
           <p>De grafiekbalken hieronder bevatten drie groepen resultaten, resultaten per groep, resultaten per vraag en algemene resultaten.</p>
           <p>Elke balk in een staafdiagram komt overeen met een specifieke categorie of gegevenspunt op de x-as van het diagram. De lengte of hoogte van de balk vertegenwoordigt de waarde die is gekoppeld aan het gegevenspunt op de y-as van het diagram.</p>
           <p>De schaal van de foutbalken is afhankelijk van de grootte van de standaarddeviatiewaarden in de antwoorden van de respondenten. Grotere standaarddeviatiewaarden resulteren in grotere foutbalken, wat wijst op grotere onzekerheid of variabiliteit in de gegevens, terwijl kortere foutbalken meer nauwkeurige gegevens of minder variabiliteit vertegenwoordigen.</p>
        </div>
      </div>
    </div>


  <div class="row">
      <div class="col-sm-12">
        <div class="row down">
          <card :hovertext="''" :cardId="'Gemiddeld antwoord per vraag'" 
            :x="vragenNumber" 
            :y="antwoorden_mean"
            :xtitle="'Sector'"  :ytitle="'Antwoorden mean'" 
            :tableHeight="300">
              <template slot="card-header" >Gemiddeld antwoord per vraag</template>
              <div slot="card-body">
                De nummers in de y-as betekenen het volgende: 1 = helemaal niet gerealiseerd, 2 = grotendeels niet gerealiseerd, 3 = ongeveer de helft gerealiseerd, 4 = grotendeels gerealiseerd en 5 = volledig gerealiseerd.
                <barchart-errors v-if="load.graphOne" 
                :xtitle="''"
                :hovertext="[vragen]"
                :tickmode="'array'"
                :tickvals="vragenNumber"
                :dtick="1"
                :x="[vragenNumber]"
                :y="[antwoorden_mean]"
                :error_y="[antwoorden_std]"/>
              </div>
          </card>
        </div>
      </div>
  </div>

  <div class="row">
      <div class="col-sm-12">
        <div class="row down">
          <card :hovertext="''" :cardId="'Gemiddeld antwoord per achtergrond respondent'" 
            :x="sector_cluster" 
            :y="antwoorden_mean_overall"
            :xtitle="'Sector'"  :ytitle="'Antwoorden mean'" 
            :tableHeight="300">
              <template slot="card-header" >Gemiddeld antwoord per achtergrond respondent</template>
              <div slot="card-body">
                De nummers in de y-as betekenen het volgende: 1 = helemaal niet gerealiseerd, 2 = grotendeels niet gerealiseerd, 3 = ongeveer de helft gerealiseerd, 4 = grotendeels gerealiseerd en 5 = volledig gerealiseerd.
                <barchart-errors v-if="load.graphThree" 
                :xtitle="''"
                :tickmode="'array'"
                :tickvals="sector_cluster"
                :dtick="1"
                :x="[sector_cluster]" 
                :y="[antwoorden_mean_overall]"
                :error_y="[antwoorden_std_overall]"/>
              </div>
          </card>
        </div>
      </div>
  </div>



<!--     <div >
      <div v-for="(vraagId, index) in selectedQuestionIdsFinal" :key="vraagId">
        <div class="row">
          <div class="col-sm-12">
            <card-header-large :cardId="'resultaten-netwerkanalyse-4'"
            :hovertext="''"
            :collapse="true">
              <template slot="card-header">Vraag {{vraagId}}: {{vragen[index]}}</template>
                <div slot="card-body">
                  <barchart-errors v-if="load.graphTwo" 
                  :xtitle="'Achtergrond respondent'" 
                  :ytitle="'Gemiddeld antwoord'"
                  :x="[all_data_sector_cluster.filter(item => item.vraag_id === vraagId).map(item => item.sector_cluster)]" 
                  :y="[all_data_sector_cluster.filter(item => item.vraag_id === vraagId).map(item => item.antwoord_id_mean)]" 
                  :error_y="[all_data_sector_cluster.filter(item => item.vraag_id === vraagId).map(item => item.antwoord_id_std)]" 
                  :tickangle="0"
                  :height="550"/>
                </div>
            </card-header-large>
          </div>
        </div>
      </div>
    </div> -->


      <div class="row" v-for="(vraagId) in selectedQuestionIdsFinal" :key="vraagId">
        <div class="col-sm-12 down">
          <card :hovertext="''" :cardId="`Vraag ${vragen[vraagId-1]}`" 
            :x="[all_data_sector_cluster.filter(item => item.vraag_id === vraagId).map(item => item.sector_cluster)]" 
            :y="[all_data_sector_cluster.filter(item => item.vraag_id === vraagId).map(item => item.antwoord_id_mean.toFixed(1))]"
            :xtitle="'Sector'"  :ytitle="'Antwoorden mean'" 
            :tableHeight="300">
            <template slot="card-header">Vraag {{vragen[vraagId-1]}}</template>
            <div slot="card-body">
              De nummers in de y-as betekenen het volgende: 1 = helemaal niet gerealiseerd, 2 = grotendeels niet gerealiseerd, 3 = ongeveer de helft gerealiseerd, 4 = grotendeels gerealiseerd en 5 = volledig gerealiseerd.
              <barchart-errors v-if="load.graphTwo"
                :xtitle="'Achtergrond respondent'"
                :names="['Achtergrond respondent']"
                :tickmode="'array'"
                :tickvals="all_data_sector_cluster.filter(item => item.vraag_id === vraagId).map(item => item.sector_cluster)"
                :dtick="1"
                :x="[all_data_sector_cluster.filter(item => item.vraag_id === vraagId).map(item => item.sector_cluster)]" 
                :y="[all_data_sector_cluster.filter(item => item.vraag_id === vraagId).map(item => item.antwoord_id_mean.toFixed(1))]"
                :error_y="[all_data_sector_cluster.filter(item => item.vraag_id === vraagId).map(item => item.antwoord_id_std.toFixed(1))]"   
                :tickangle="0"
                :height="550"
                :showlegend="false"/>
            </div>
          </card>
        </div>
    </div>
    <button class="btn btn-secondary" @click="backToMenu" >Terug naar menu</button>

  </div>
</div>
</div>


</template>

<script>
  import Card from '../components/Card'
  // import CardLarge from '../components/CardLarge'
  import BarchartErrors from '../components/BarchartErrors'
  import nrinformation from '@/assets/netwerkanalyse_resultaten_information.svg'
  import one from '@/assets/one_blue.svg'
  import two from '@/assets/two_blue.svg'
  import three from '@/assets/three_blue.svg'
  import VueMeta from 'vue-meta'

  export default{
  mixins: [VueMeta],
  metaInfo: {
    title: 'Respondenten Resultaten',
  },
  name: 'RespondentenResultaten',
  components: {Card, BarchartErrors}, 
  data(){ 
      return {
        nrinformation: nrinformation,
        one: one,
        two: two,
        three: three,
        errorMessage: '',
        //filterClient: 'Cliënt',
        vragen: [],
        vragenNumber: [],
        questionIds: [],
        questions: [],
        questionGroupData: [],
        questionGroups: [],
        questionGroupIds: [],
        selectedQuestionGroupIds: [],
        selectedQuestionIds: [],
        selectedQuestionIdsFinal: [],
        questionIdsRemoval: [],
        antwoorden_mean: [],
        antwoorden_std: [],
        all_data_sector_cluster: [],
        uniqueVraagIds: [],
        selectedVraagIds: [],
        sector_cluster: [],
        antwoorden_mean_overall: [],
        antwoorden_std_overall: [],
        load: {
          graphOne: false,
          graphTwo: false,
          graphThree: false
        }
      }
  },
  methods: {
    loadData() {
      this.$http.get(`api/respondenten_netwerkanalyse/get_data_per_question/`).then(
        (response) => {
        this.vragen = response.data.map(d => (d.vraag))
        this.vragenNumber = response.data.map(d => parseInt(d.vraag.match(/^\d+/)[0]))
        this.antwoorden_mean = response.data.map(d => (d.antwoord_id_mean).toFixed(1))
        this.antwoorden_std = response.data.map(d => (d.antwoord_id_std).toFixed(1))
        this.load.graphOne = true
        }
      ).catch(error => {
          console.error(error)
          if(error.response.status == 500){
            this.errorMessage = 'Er is nog geen netwerkanalyse ingevuld. Zodra ten minste 1 respondent de netwerkanalyse heeft ingevuld, zijn de resultaten hier te zien.' 
          }
          else if(error.response.status == 401){
            this.errorMessage = 'U hebt geen toegang tot deze pagina.' 
          }
          else {
            this.errorMessage = 'Onbekende foutmelding. Contacteer alstublieft dementiedata@amsterdamdatacollective.com.'
          }
    }),
      this.$http.get(`api/respondenten_netwerkanalyse/get_data_per_sector_cluster/`).then(
        (response) => {
        this.all_data_sector_cluster = response.data
        this.uniqueVraagIds = [...new Set(this.all_data_sector_cluster.map(item => item.vraag_id))]
        this.selectedVraagIds = [...new Set(this.all_data_sector_cluster.map(item => item.vraag_id))]
        this.load.graphTwo = true
        }
      ).catch(error => {
          console.error(error)
          if(error.response.status == 500){
            this.errorMessage = 'Er is nog geen netwerkanalyse ingevuld. Zodra ten minste 1 respondent de netwerkanalyse heeft ingevuld, zijn de resultaten hier te zien.' 
          }
          else if(error.response.status == 401){
            this.errorMessage = 'U hebt geen toegang tot deze pagina.' 
          }
          else {
            this.errorMessage = 'Onbekende foutmelding. Contacteer alstublieft dementiedata@amsterdamdatacollective.com.'
          }
    }),
      this.$http.get(`api/respondenten_netwerkanalyse/get_data_per_sector_cluster_averaged/`).then(
        (response) => {
        this.sector_cluster = response.data.map(d => (d.sector_cluster))
        this.antwoorden_mean_overall = response.data.map(d => (d.antwoord_id_mean).toFixed(1))
        this.antwoorden_std_overall = response.data.map(d => (d.antwoord_id_std).toFixed(1))
        this.load.graphThree = true
        }
      ).catch(error => {
          console.error(error)
          if(error.response.status == 500){
            this.errorMessage = 'Er is nog geen netwerkanalyse ingevuld. Zodra ten minste 1 respondent de netwerkanalyse heeft ingevuld, zijn de resultaten hier te zien.' 
          }
          else if(error.response.status == 401){
            this.errorMessage = 'U hebt geen toegang tot deze pagina.' 
          }
          else {
            this.errorMessage = 'Onbekende foutmelding. Contacteer alstublieft dementiedata@amsterdamdatacollective.com.'
          }
    }),
     this.$http.get(`api/koppeltabel_netwerkanalyse/`).then(
      (response) => {
        this.questionGroupData = response.data
        this.questionIds = Array.from({length: this.questionGroupData.length}, (_, i) => i + 1)
        this.questionGroups = [...new Set(this.questionGroupData.map(d => d.vragengroep))] 
        this.questionGroupIds = [...new Set(this.questionGroupData.map(d => d.vragengroep_id))]
      },
      (error) => {
        console.log(error);
          if(error.response.status == 500){
            this.errorMessage = 'Er is nog geen netwerkanalyse ingevuld. Zodra ten minste 1 respondent de netwerkanalyse heeft ingevuld, zijn de resultaten hier te zien.' 
          }
          else if(error.response.status == 401){
            this.errorMessage = 'U hebt geen toegang tot deze pagina.' 
          }
          else {
            this.errorMessage = 'Onbekende foutmelding. Contacteer alstublieft dementiedata@amsterdamdatacollective.com.'
          }
      }
    )
    },
    togglePhase(questiongroup){
      const index = this.selectedQuestionGroupIds.indexOf(questiongroup);

      if (index === -1) {
        // question group is not currently selected, so add it to the list
        this.selectedQuestionGroupIds.push(questiongroup);
        this.selectedQuestionIds = this.questionGroupData.filter(x => x.vragengroep_id == this.selectedQuestionGroupIds.slice(-1)).map(d => d.vraag_id);
        this.selectedQuestionIdsFinal.push.apply(this.selectedQuestionIdsFinal, this.selectedQuestionIds)
        this.selectedQuestionIdsFinal.sort((a, b) => a - b);
        this.selectedQuestionIds = [];
      } else {
        // question group is already selected, so remove it from the list
        this.selectedQuestionGroupIds.splice(index, 1);
        this.questionIdsRemoval = this.questionGroupData.filter(x => x.vragengroep_id == questiongroup).map(d => d.vraag_id);
        this.selectedQuestionIdsFinal = this.selectedQuestionIdsFinal.filter(item => !this.questionIdsRemoval.includes(item));
      }
    },
    backToMenu() {
      this.$router.push('/zorgstandaard/Netwerkcoordinator/')
    },
  },

  mounted () {
    this.loadData()
  }
}


</script>

<style scoped>

p {
  margin-left: 10px;
}

p.border-style {
  border-style: ridge;
}

.button {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.custom-select-form{
  font-size: 16px;
  width: 50%;
  font-family: inherit;
  font-weight: normal;
  background: rgba(57, 57, 57, 0.07);
  margin: 12.5px 0;
  height: 40px;
  border: none;
  padding: 0 30px;
  border-radius: 10px;
}

.my-button-group{
  margin-top: 2em;
}

.nrinformation-img{
  max-width: 100%;
  height: auto;
}

.my-button-group .btn-outline-primary {
  outline: none;
  box-shadow: none;
  background-color: var(--color-2);
  border-color: var(--color-1);
  color: var(--color-1);
  font-weight: bold;
}

.my-button-group .btn-outline-primary:hover {
  background-color: var(--color-1);
  border-color: var(--color-1);
  color: var(--color-2);
}

.my-button-group .btn-outline-primary:focus {
  background-color: var(--color-14);
  border-color: var(--color-14);
  color: var(--color-2);
}

.my-button-group .btn-outline-primary.active {
  background-color: var(--color-1);
  border-color: var(--color-1);
  color: var(--color-2);
 }

 .my-button-group .btn-outline-primary {
  flex: 1;
  flex-basis: 120px;
}

.chart-information{ 
  padding: 30px 0 30px 0;
}

.result-information{
  font-weight: bold;
  font-size: 18px;
  color: var(--color-10);
}

.explanation-navbar{
  display: flex;
  align-items: center;
}

.explanation-navbar-text{
  font-weight: lighter;
  margin-left: 10px;
  color: var(--color-1);
}

.section-buttons{
  height: 50em;
}

.section-information{
  width: 60em;
  height: 50em;
}

.navbar-img{
  width: 100%;
}

.numbers-img{
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.button-space{
  margin: 10px;
}

.icon-space{
  display: flex;
  align-items: center;
}
.custom-icon{
  margin-right: 5px; 
}

.filter-space{
  margin-top: 5em;
}

.bold-text{
  font-weight: bold;
}

.select-toggle-button{
  width: 25rem;
}

@media only screen and (max-width: 1200px)  {

  .section-buttons{
    height: 100vh;
  }

  .select-toggle-button{
    width: 15rem;
    transition: .10s ease-in !important;
  }

  .section-information{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  .resultaten-info-section{
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin-top: 2rem;
  }

  .explanation-navbar-text{
    width: 40vw;

  }

  .explanation-navbar{
    width: 40vw;
    height: 10vh;
  }
}


.btn-secondary {
  outline: none;
  box-shadow: none;
  border: none;
  background: var(--color-1);
  font-weight: bold;
  height: 50px;
  padding-top: 5px !important;
  z-index: 12;
  margin-top: 30px;
  margin-right: 50px;
  width: 200px;
  max-width: 300px
}

.btn-secondary:focus,
.btn-secondary:hover {
  background: var(--color-14);
}

.error-message{
  padding: 3rem;
  color: #B80000;
  font-weight: bold;
}
</style>

